import Vue from "vue";

export default {
  name: "zPayHistoryCard",
  props: {
    history: {
      type: Object,
      default: []
    },
    charges: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: []
    },
    orders: {
      type: Array,
      default: []
    },
    company: {
      type: Object,
      default: []
    }
  },
  data() {
    return {
      currency: "BRL",
      checkinDate: "",
      checkoutDate: "",
      paymentTypes: {
        prepayment: this.$t("PAYMENT_SOLICITATION.CHARGE_TYPES.PREPAYMENTS"),
        warranty_registration: this.$t(
          "PAYMENT_SOLICITATION.CHARGE_TYPES.WARRANTY_REGISTRATION"
        ),
        separate_payment: this.$t(
          "PAYMENT_SOLICITATION.CHARGE_TYPES.SEPARETE_PAYMENT"
        ),
        no_show: this.$t("PAYMENT_SOLICITATION.CHARGE_TYPES.NO_SHOW")
      },
      chargesType: {
        paid: this.$t("PAYMENT_SOLICITATION.PAYMENT_STATUS.PAID"),
        canceled: this.$t("PAYMENT_SOLICITATION.PAYMENT_STATUS.CANCELLED"),
        failed: this.$t("PAYMENT_SOLICITATION.PAYMENT_STATUS.FAILED"),
        processing: this.$t("PAYMENT_SOLICITATION.PAYMENT_STATUS.PROCESSING"),
        partially_paid: this.$t(
          "PAYMENT_SOLICITATION.PAYMENT_STATUS.PARTIALLY_PAID"
        ),
        uncompleted: this.$t("PAYMENT_SOLICITATION.PAYMENT_STATUS.UNCOMPLETED"),
        authorized: this.$t("PAYMENT_SOLICITATION.PAYMENT_STATUS.AUTHORIZED"),
        processing_refund: this.$t(
          "PAYMENT_SOLICITATION.PAYMENT_STATUS.PROCESSING_REFUND"
        ),
        with_error: this.$t("GLOBAL.ERROR")
      },
      shouldShowMessage: false
    };
  },
  async mounted() {
    await this.updateHistory();
    await this.reloadPage();
  },
  methods: {
    async reloadPage() {
      let self = this;
      setTimeout(function() {
        if (this.location.pathname === "/payment/history") {
          self.updateHistory();
        }
      }, 5000);
    },
    async updateHistory() {
      this.$emit("update");
    },
    reload() {
      Vue.$globalEvent.$emit("reload");
    }
  }
};
