// import { zPayHistoryCard } from "@zoox-framework/zoox-components";
import zPayHistoryCard from "@/components/zPayHistoryCard";
import OrderService from "@/services/OrderService";
import CompanyService from "@/services/CompanyService";
import { socketMixin } from "@zoox-framework/smck-service";
import PersonService from "@/services/PersonService";
import moment from "moment";
export default {
  name: "PaymentHistory",
  mixins: [socketMixin],
  components: {
    zPayHistoryCard
  },
  data() {
    return {
      orderService: new OrderService(),
      companyService: new CompanyService(),
      personService: new PersonService(),
      history: {},
      charges: [],
      items: [],
      orders: [],
      company: {}
    };
  },
  async created() {
    await this.prepareHistoryCard();
  },
  mounted() {
    const $context = this;

    const personId = this.personService.getPersonId();
    $context.$socketEmit("centralpay-back:join-user", {
      personId: personId
    });

    $context.$socketOn("centralpay-front:update-payment-user", () => {
      this.prepareHistoryCard();
    });
  },
  methods: {
    back() {
      this.$router.back();
    },
    async prepareHistoryCard() {
      this.company = this.companyService.getCurrentCompany();
      const personId = this.personService.getPersonId();

      this.history = await this.orderService.getBillingHistory(
        this.company.id,
        personId
      );
      this.history.items.forEach(item => {
        this.items[item._id] = item;
      });
      this.history.orders.forEach(order => {
        this.orders[order._id] = {
          currency: order.currency || this.currency,
          checkinDate: moment
            .utc(order.metadata.checkinDate)
            .endOf("day")
            .format(this.$t("GLOBAL.DATE_FORMAT")),
          checkoutDate: moment
            .utc(order.metadata.checkoutDate)
            .endOf("day")
            .format(this.$t("GLOBAL.DATE_FORMAT"))
        };
      });

      this.charges = this.history.charges
        .filter(
          charge => charge.order && this.orders[charge.order.id] !== undefined
        )
        .map(charge => {
          const order = this.orders[charge.order.id];

          return {
            ...charge,
            currency: order.currency || this.currency,
            checkinDate: order.checkinDate,
            checkoutDate: order.checkoutDate
          };
        });

      if (this.charges.length === 0) {
        this.shouldShowMessage = true;
      }
    }
  }
};
